import PropTypes from 'prop-types';
import React from 'react';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MuiDatePicker from '@mui/lab/DatePicker';
import {TextField} from '@mui/material';
import AdapterMoment from '@mui/lab/AdapterMoment';
import {Controller} from 'react-hook-form';
import Calendar from '/public/icons/calendar.svg';
import 'moment/locale/ru';

const DatePickerControl = ({control, name, label, placeholder, ...rest}) => {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Controller
                defaultValue={rest.defaultValue}
                name={name}
                control={control}
                render={({field: {ref, ...props}, fieldState: {error}}) => (
                    <MuiDatePicker
                        {...rest}
                        label={label}
                        format="dd.MM.yyyy"
                        components={{
                            OpenPickerIcon: Calendar
                        }}
                        renderInput={params => (
                            <TextField
                                helperText={error?.message}
                                InputLabelProps={{shrink: true}}
                                error={!!error}
                                variant={'standard'}
                                size={rest.size}
                                {...params}
                            />
                        )}
                        {...props}
                    />
                )}
            />
        </LocalizationProvider>
    );
};

export default DatePickerControl;

DatePickerControl.propTypes = {
    control: PropTypes.object.isRequired,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    size: PropTypes.string
};

DatePickerControl.defaultProps = {
    size: 'medium'
};
