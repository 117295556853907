import PropTypes from 'prop-types';
import {Controller} from 'react-hook-form';
import React from 'react';
import {TextField} from 'components/inputs';
import {InputAdornment} from '@mui/material';
import {Clear} from '@mui/icons-material';

const TextFieldControl = ({name, rules, defaultValue, control, setValue, withClearButton, onInputChange, ...props}) => {
    return (
        <Controller
            name={name}
            render={({field, fieldState}) => (
                <TextField
                    InputProps={{
                        endAdornment: withClearButton && (
                            <InputAdornment
                                sx={{cursor: 'pointer'}}
                                position={'end'}
                                onClick={() => setValue(name, '')}>
                                <Clear />
                            </InputAdornment>
                        )
                    }}
                    {...{...field, ...props, ...fieldState}}
                    value={field.value === null ? '' : field.value}
                />
            )}
            rules={rules}
            control={control}
            defaultValue={defaultValue}
        />
    );
};

export default TextFieldControl;

TextFieldControl.propTypes = {
    colorIcon: PropTypes.string,
    control: PropTypes.object.isRequired,
    defaultValue: PropTypes.any,
    disableAutoComplete: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    multiline: PropTypes.bool,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    rules: PropTypes.object,
    setValue: PropTypes.any,
    size: PropTypes.oneOf(['small', 'medium']),
    sx: PropTypes.object,
    type: PropTypes.string,
    variant: PropTypes.string,
    withClearButton: PropTypes.bool,
    withSearchIcon: PropTypes.bool,
    min: PropTypes.number,
    allowNegative: PropTypes.bool,
    mask: PropTypes.string,
    rows: PropTypes.number
};

TextFieldControl.defaultProps = {
    label: '',
    placeholder: '',
    size: 'medium',
    type: 'text',
    variant: 'standard',
    withSearchIcon: false,
    colorIcon: '#222D47',
    allowNegative: false
};
