const cardStyles = {
    MuiCardHeader: {
        styleOverrides: {
            root: {
                paddingRight: 24,
                paddingLeft: 24,
                paddingBottom: 8,
                paddingTop: 30
            }
        }
    },
    MuiCardContent: {
        styleOverrides: {
            root: {
                padding: 24
            }
        }
    }
};

export default cardStyles;
