import React, {useState} from 'react';
import {Stack, Typography, IconButton, Box} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Hint = props => {
    return (
        <>
            <Box
                sx={{
                    position: 'absolute',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: 350,
                    backgroundColor: 'secondary.main',
                    p: 1.5,
                    pl: 3,
                    top: 123,
                    right: 52,
                    boxShadow: '0px 3px 6px rgba(140, 152, 164, 0.25)',
                    borderRadius: '8px',
                    borderLeft: '8px solid ' + props.color
                }}>
                <Typography variant={'h6'}>{props.text}</Typography>
                <CloseIcon sx={{cursor: 'pointer', color: 'grey.verylight'}} onClick={() => props.closeHint()} />
            </Box>
        </>
    );
};

export default Hint;
