export default {
    typography: {
        fontFamily: 'MuseoSansCyrl',
        t1: {
            fontSize: 48,
            fontWeight: 600,
            lineHeight: '58px'
        },
        h1: {
            fontSize: 35,
            fontWeight: 600,
            letterSpacing: '0.5px',
            color: '#21325B'
        },
        h2: {
            fontSize: 24,
            fontWeight: 600,
            letterSpacing: '0.5px',
            color: '#21325B'
        },
        // Временно?
        h3: {
            fontSize: 20,
            fontWeight: 600,
            lineHeight: '24px',
            color: '#242E45'
        },
        h4: {
            fontSize: 18,
            fontWeight: 600,
            letterSpacing: '0.3px',
            color: '#2D3748',
            lineHeight: '26px'
        },
        h5: {
            color: '#2D3748',
            fontSize: 16,
            lineHeight: '24px',
            fontWeight: 600
        },
        h6: {
            color: '#242E45',
            fontSize: 16,
            lineHeight: '21px',
            fontWeight: 400
        },
        body1: {
            fontWeight: 400,
            fontSize: 16,
            lineHeight: '23px'
        },
        body2: {
            fontWeight: 400,
            fontSize: 14,
            lineHeight: '20px'
        },
        verySmall: {
            fontSize: '12px',
            lineHeight: '14px'
        },
        button: {
            textTransform: 'initial'
        }
    }
};
