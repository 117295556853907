import {createTheme} from '@mui/material/styles';
import {red} from '@mui/material/colors';
import tableStyles from 'theme/tableStyles';
import inputStyles from 'theme/inputStyles';
import cardStyles from 'theme/cardStyles';
import tabStyles from 'theme/tabStyles';
import cssBaseline from 'theme/cssBaseline';
import typography from 'theme/typography';
// Create a theme instance.
const index = createTheme({
    ...typography,
    palette: {
        primary: {
            main: '#D12E28'
        },
        secondary: {
            main: '#FFFFFF'
        },
        error: {
            main: red.A400
        },
        divider: '#E5E5E5',
        text: {
            header: '#222D47'
        },
        grey: {
            description: '#646E73',
            light: '#98999C',
            verylight: '#BDBDBD',
            dark: '#4A5067'
        },
        green: {
            light: '#4CAF50'
        },
        blue: {
            dark: '#242E45',
            purple: '#3F51B5'
        },
        white: '#FFF'
    },
    shape: {
        borderRadius: 8
    },
    components: {
        MuiCssBaseline: cssBaseline,
        ...tableStyles,
        ...inputStyles,
        ...cardStyles,
        ...tabStyles,
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: '0px 6px 24px rgba(140, 152, 165, 0.13)'
                }
            }
        },
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    t1: 'h1'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: '0px 6px 24px 0px #8C98A521',
                    textTransform: 'initial',
                    borderRadius: 5,
                    maxHeight: 42,
                    whiteSpace: 'nowrap'
                },
                textPrimary: {
                    boxShadow: 'none'
                },
                linkPrimary: {
                    color: '#D12E28',
                    backgroundColor: 'inherit',
                    boxShadow: 'none',
                    textDecoration: 'underline',
                    padding: 0,
                    '&:hover': {
                        backgroundColor: 'inherit',
                        color: '#D12E28'
                    }
                },
                // Белая кнопка
                containedSecondary: {
                    color: '#21325B',
                    '&:hover': {
                        backgroundColor: '#D12E28',
                        color: '#fff'
                    }
                },
                // Красная кнопка
                containedPrimary: {
                    color: '#FFFFFF',
                    '&:hover': {
                        backgroundColor: '#BD0F09'
                    },
                    '&:disabled': {
                        backgroundColor: '#EDEDED',
                        color: '#BDBDBD'
                    }
                },
                outlinedPrimary: {
                    border: '1px solid #D12E28',
                    color: '#D12E28',
                    '&:hover': {
                        color: '#FFFFFF',
                        backgroundColor: '#D12E28'
                    }
                },
                sizeSmall: {
                    width: '100px',
                    height: '30px'
                },
                sizeLarge: {
                    width: '152px',
                    height: '56px',
                    fontSize: '1rem',
                    maxHeight: 'none'
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    textDecorationColor: '#2D3748',
                    color: '#2D3748',
                    cursor: 'pointer'
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        cursor: 'not-allowed'
                    }
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: 14,
                    padding: 8 * 2,
                    backgroundColor: '#fff',
                    color: '#242E45',
                    lineHeight: '20px',
                    opacity: 1,
                    boxShadow: '0px 6px 24px rgba(140, 152, 165, 0.13)'
                },
                arrow: {
                    color: '#fff',
                    boxShadow: '0px 6px 24px rgba(140, 152, 165, 0.13)'
                }
            }
        },
        MuiContainer: {
            defaultProps: {
                maxWidth: 'xl'
            },
            styleOverrides: {
                root: ({theme}) => ({
                    [theme.breakpoints.down('md')]: {
                        padding: '0 40px'
                    },
                    [theme.breakpoints.up('md')]: {
                        padding: '0 80px'
                    }
                })
            }
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    '& .MuiSvgIcon-root': {
                        fill: '#D12E28'
                    }
                }
            }
        }
    }
});

export default index;
