const inputStyles = {
    MuiInputLabel: {
        styleOverrides: {
            root: {
                fontSize: 14,
                color: '#2D3748',
                '&.Mui-focused': {
                    color: '#2D3748'
                },
                '& + .MuiInput-root': {
                    marginTop: 2.5 * 8
                },
                transform: 'none'
            }
        }
    },
    MuiInput: {
        styleOverrides: {
            root: {
                backgroundColor: '#FFFFFF',
                border: '1px solid',
                borderRadius: 5,
                borderColor: '#E6E6E6',
                '&:before, &:hover:not(.Mui-disabled):before, &:after, &.Mui-disabled:before': {
                    borderBottom: 'none',
                    borderBottomStyle: 'none'
                },
                '&.Mui-disabled': {
                    backgroundColor: '#F4F4F4'
                },
                maxHeight: 56,
                padding: 2 * 8,
                '&.Mui-error': {
                    borderColor: '#F44336'
                }
            },
            input: {
                color: '#646E73',
                '&::placeholder': {
                    textOverflow: 'ellipsis !important',
                    color: '#646E73'
                }
            },
            sizeSmall: {
                padding: 8,
                height: '100%',
                maxHeight: 42,
                marginTop: 0,
                '& .MuiInput-input': {
                    padding: 0
                }
            }
        }
    },
    MuiTimePicker: {
        styleOverrides: {
            root: {
                '& .MuiInputAdornment-root': {
                    display: 'none'
                }
            }
        }
    },
    MuiAutocomplete: {
        styleOverrides: {
            root: {
                '& .MuiInput-root .MuiInput-input': {
                    padding: 0
                },
                '& .MuiInput-root': {
                    paddingBottom: 16
                }
            }
        }
    },
    MuiFormHelperText: {
        styleOverrides: {
            root: {
                position: 'absolute',
                top: '100%',
                marginRight: 0,
                marginLeft: 0
            }
        }
    },
    MuiSelect: {
        styleOverrides: {
            select: {
                '&.MuiInputBase-input:not(.MuiTablePagination-select)': {
                    paddingRight: 0
                }
            }
        }
    }
};

export default inputStyles;
