const tableStyles = {
    MuiTableCell: {
        styleOverrides: {
            root: {
                border: 'none',
            },
            head: {
                borderBottom: '1px solid #E5E5E5',
                backgroundColor: '#FFFFFF',
                color: '#2D3748',
                fontSize: 14,
                lineHeight: '20px',
                fontWeight: 400,
                paddingTop: 5 *8,
                paddingLeft: 0,
                paddingRight: 8,

                '&:first-of-type': {
                    paddingLeft: 5*8
                },
                '&:last-of-type': {
                    paddingRight: 5*8
                }
            },
            body: {
                fontSize: 16,
                color: '#2D3748',
                paddingTop: 16,
                paddingBottom: 16,
                paddingLeft: 0,

                '&:first-of-type': {
                    paddingLeft: 40
                },
                '&:last-of-type': {
                    paddingRight: 40
                }
            }
        }
    },
    MuiTableRow: {
        styleOverrides: {
            root: {
                '&:nth-of-type(even)': {
                    backgroundColor: '#F8F9FC'
                }
            }
        }
    },

    MuiTablePagination: {
        styleOverrides: {
            root: {
                borderTop: '1px solid #E5E5E5',
            },
            selectLabel: {
                color: '#2D3748',
                fontSize: 14
            },
            displayedRows: {
                color: '#2D3748',
                fontSize: 14
            }
        }
    }
};

export default tableStyles;
