import React from 'react';
import {Controller} from 'react-hook-form';
import PropTypes from 'prop-types';
import Autocomplete from 'components/inputs/autocomplete/Autocomplete';

const AutocompleteControl = props => {
    const {options, control, name, multiple = false, defaultValue} = props;
    return (
        <Controller
            render={({field, fieldState}) => (
                <Autocomplete
                    {...{...field, ...props, ...fieldState}}
                    value={
                        multiple
                            ? field.value
                                ? field.value
                                : []
                            : typeof field.value === 'string'
                            ? options.find(item => field.value === item.id)
                            : field.value
                    }
                    onChange={(_, data) => field.onChange(data)}
                />
            )}
            defaultValue={defaultValue}
            name={name}
            control={control}
        />
    );
};

export default AutocompleteControl;

AutocompleteControl.propTypes = {
    control: PropTypes.object.isRequired,
    defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    disabled: PropTypes.bool,
    freeSolo: PropTypes.bool,
    getOptionDisabled: PropTypes.func,
    getOptionLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    getOptionSelected: PropTypes.func,
    isOptionDisabled: PropTypes.bool,
    isRenderOption: PropTypes.bool,
    label: PropTypes.string,
    limitTags: PropTypes.number,
    multiple: PropTypes.bool,
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    renderOption: PropTypes.any,
    rules: PropTypes.object,
    size: PropTypes.string,
    tagLink: PropTypes.string
};

AutocompleteControl.defaultProps = {
    disabled: false,
    freeSolo: false,
    getOptionLabel: 'value',
    limitTags: -1,
    multiple: false,
    rules: {},
    size: 'medium'
};
