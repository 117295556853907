import {ApolloClient, ApolloLink, InMemoryCache, Observable} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import fetch from 'node-fetch';
import {withApollo as withApolloHOC} from 'next-apollo';
import {
    EXEC_NO_SSR_SKIP,
    getAccessToken,
    getExecType,
    getRedirectUrl,
    getRefreshToken,
    redirectTo,
    removeCookies,
    setCookies
} from './execHelper';
import moment from 'moment';
import {BASE_GRAPHQL_URL} from 'config';
import {createUploadLink} from 'apollo-upload-client';

let isRefreshing = false;
let failedRequestsQueue = [];

const httpLink = createUploadLink({
    fetch,
    uri: BASE_GRAPHQL_URL
});

const processQueue = async (error, token = null) => {
    failedRequestsQueue.forEach(prom => (error ? prom.reject(error) : prom.resolve(token)));
    failedRequestsQueue = [];
    isRefreshing = false;
};

const refreshAccessToken = async (execType, ctx) => {
    const r = await fetch(BASE_GRAPHQL_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'JWT ' + getAccessToken(execType, ctx)
        },
        body: JSON.stringify({
            query: `mutation refreshToken {refreshToken(refreshToken: "${getRefreshToken(execType, ctx)}") {
                                            accessToken
                                            refreshToken
                                        }}`
        })
    });
    return await r.json();
};

export const createClient = ctx => {
    const execType = getExecType(ctx);

    const authMiddleware = new ApolloLink((operation, forward) => {
        const token = getAccessToken(execType, ctx);
        operation.setContext({headers: {authorization: token ? 'JWT ' + token : null}});

        return forward(operation);
    });

    return new ApolloClient({
        link: ApolloLink.from([
            onError(({graphQLErrors, networkError, operation, forward}) => {
                if (graphQLErrors)
                    graphQLErrors.forEach(({message, locations, path}) =>
                        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
                    );
                if (networkError) console.log(`[Network error]: ${networkError}`);
                if (
                    graphQLErrors?.find(({message}) => message === 'Unauthorized') &&
                    execType !== EXEC_NO_SSR_SKIP &&
                    operation.operationName !== 'login' &&
                    operation.operationName !== 'me'
                ) {
                    return new Observable(async observer => {
                        //Кладем запрос в очередь отклоненных запросов, там он будет ждать решения по обновлению токена
                        new Promise((resolve, reject) => {
                            failedRequestsQueue.push({resolve, reject});
                        })
                            .then(accessToken => {
                                //Если все ок, то идем дальше, пуская вперед остальные запросы;
                                const subscriber = {
                                    next: observer.next.bind(observer),
                                    error: observer.error.bind(observer),
                                    complete: observer.complete.bind(observer)
                                };
                                if (ctx) ctx.accessToken = accessToken;
                                forward(operation).subscribe(subscriber);
                            })
                            .catch(e => {
                                //Refresh-токен тоже просрочен, редирект на авторизацию произведет первый запрос в очереди отклоненных
                            });
                        //Если данный запрос первый в очереди отклоненных, то есть до него никто не поставил isRefreshing
                        if (!isRefreshing) {
                            isRefreshing = true;
                            try {
                                //TODO: на бэкенде отключен рефреш, так что тут может падать
                                throw new Error('Refresh token on backend is not available');
                                //Идем вручную на рефреш токена, ибо клиент Apollo испорчен старым токеном до момента обновления
                                const data = await refreshAccessToken(execType, ctx);

                                //Если токен не получилось обновить, идем на авторизацию
                                if (data.errors?.length) {
                                    throw new Error('Error refreshing token');
                                }
                                //Если все ок, обновляем токен
                                const {
                                    data: {
                                        refreshToken: {accessToken, refreshToken}
                                    }
                                } = data;
                                setCookies(execType, ctx, [
                                    {
                                        name: 'accessToken',
                                        value: accessToken,
                                        options: {expires: moment().add(30, 'days').toDate()}
                                    },
                                    {
                                        name: 'refreshToken',
                                        value: refreshToken,
                                        options: {expires: moment().add(30, 'days').toDate()}
                                    }
                                ]);
                                //Запускаем очередь отклоненных запросов с новым токеном
                                await processQueue(null, accessToken);
                            } catch (e) {
                                await processQueue(e, null);
                                //Аналогично ошибкам GQL, если не достучались до сервера вообще, идем на авторизацию
                                removeCookies(execType, ctx, ['accessToken', 'refreshToken']);

                                redirectTo(execType, ctx, getRedirectUrl(ctx));
                            }
                        }
                    });
                }
            }),
            authMiddleware,
            httpLink
        ]),
        cache: new InMemoryCache()
    });
};

export const withApollo = withApolloHOC(createClient);
