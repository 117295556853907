import React from 'react';
import {InputAdornment} from '@mui/material';
import MuiTextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import {IMaskInput} from 'react-imask';
import {SearchOutlined} from '@mui/icons-material';

// const PhoneMask = React.forwardRef(function PhoneMask(props, ref) {
//     const {onChange, ...other} = props;
//     return (
//         <IMaskInput
//             {...other}
//             mask="(000) 000-00-00"
//             definitions={{
//                 '#': /[1-9]/
//             }}
//             inputRef={ref}
//             onAccept={value => onChange({target: {name: props.name, value}})}
//             overwrite
//         />
//     );
// });

const IntegerMask = React.forwardRef(function PositiveIntegerMask(props, ref) {
    const {onChange, ...other} = props;
    return (
        <IMaskInput
            {...other}
            mask={props.mask}
            inputRef={ref}
            value={props.value?.toString()}
            onAccept={value => {
                onChange({target: {name: props.name, value}});
            }}
        />
    );
});

const TextField = props => {
    const {placeholder, label, variant, disableAutoComplete, type, withSearchIcon, mask, colorIcon, min, onChange} =
        props;
    return (
        <MuiTextField
            {...props}
            fullWidth
            placeholder={placeholder || label}
            InputLabelProps={{shrink: true}}
            variant={variant || 'standard'}
            autoComplete={disableAutoComplete && 'new-password'}
            inputProps={{
                autoComplete: disableAutoComplete && 'new-password'
            }}
            helperText={props.error?.message}
            InputProps={{
                ...props.InputProps,
                style: {maxHeight: props.multiline ? 130 : 56},
                inputComponent: type === 'number' ? IntegerMask : undefined,
                name: props.name,
                type: type === 'number' ? 'text' : type,
                inputProps: {signed: props.allowNegative, min, mask: props.mask},
                startAdornment: withSearchIcon && (
                    <InputAdornment position="start">
                        <SearchOutlined sx={{color: colorIcon}} />
                    </InputAdornment>
                )
            }}
        />
    );
};

export default TextField;

TextField.propTypes = {
    InputProps: PropTypes.any,
    allowNegative: PropTypes.bool,
    colorIcon: PropTypes.string,
    disableAutoComplete: PropTypes.bool,
    error: PropTypes.any,
    label: PropTypes.string,
    mask: PropTypes.string,
    min: PropTypes.number,
    name: PropTypes.any,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    variant: PropTypes.string,
    withSearchIcon: PropTypes.bool,
    multiline: PropTypes.bool,
    rows: PropTypes.number
};

TextField.defaultProps = {
    allowNegative: false,
    colorIcon: 'text.header',
    min: 0,
    type: 'text',
    variant: 'standard',
    withSearchIcon: false
    // mask: Number
};
