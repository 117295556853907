import React from 'react';
import MuiSwitch from '@mui/material/Switch';
import {FormControlLabel, FormGroup} from '@mui/material';
import {Controller} from 'react-hook-form';

const SwitchControl = ({label, control, name, disabled, defaultValue, defaultChecked}) => {
    return (
        <Controller
            defaultValue={defaultValue}
            render={({field: {value, onChange}}) => (
                <FormGroup>
                    <FormControlLabel
                        control={
                            <MuiSwitch
                                defaultChecked={defaultChecked}
                                disabled={disabled}
                                onChange={e => onChange(e.target.checked)}
                                checked={defaultChecked ? true : value}
                            />
                        }
                        label={label}
                    />
                </FormGroup>
            )}
            name={name}
            control={control}
        />
    );
};

export default SwitchControl
