import PropTypes from 'prop-types';
import React from 'react';
import {Controller} from 'react-hook-form';
import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from '@mui/material';

const DropdownControl = props => {
    const {control, name, label, items, placeholder, defaultValue, disabled, placeholderValue} = props;
    return (
        <Controller
            defaultValue={defaultValue}
            control={control}
            name={name}
            render={({field, fieldState: {error}}) => (
                <FormControl fullWidth disabled={disabled} size={props.size}>
                    {label && <InputLabel shrink={true}>{label}</InputLabel>}
                    <Select
                        {...field}
                        MenuProps={{
                            // PaperProps: {
                            //     style: {
                            //         maxHeight: 35 * 4.5,
                            // width: 250
                            //     }
                            // },
                            // variant: "menu",
                            // getContentAnchorEl: null,
                            disableScrollLock: true
                        }}
                        IconComponent={() => <></>}
                        variant={'standard'}
                        error={!!error?.message}>
                        {placeholder && (
                            <MenuItem disabled value={placeholderValue}>
                                {placeholder}
                            </MenuItem>
                        )}
                        {items?.map((item, key) => (
                            <MenuItem key={`select-item-${name}-${key}`} value={item.id}>
                                {item.value || item.id}
                            </MenuItem>
                        ))}
                    </Select>
                    {!!error?.message && (
                        <FormHelperText error={true} sx={{position: 'absolute', top: '100%'}}>
                            {error.message}
                        </FormHelperText>
                    )}
                </FormControl>
            )}
        />
    );
};

export default DropdownControl;

DropdownControl.propTypes = {
    control: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    size: PropTypes.oneOf(['medium', 'small'])
};

DropdownControl.defaultProps = {
    items: [],
    size: 'medium'
};
