import React from 'react';
import {Box, SvgIcon} from '@mui/material';
import ArrowDown from '/public/icons/chevron-down.svg';
import CustomInput from 'components/inputs/autocomplete/components/CustomInput';
import MuiAutocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';

const Autocomplete = props => {
    const {
        multiple,
        value,
        isOptionDisabled,
        getOptionDisabled,
        options,
        limitTags,
        disabled,
        freeSolo,
        getOptionLabel,
        isRenderOption,
        renderOption,
        label,
        size,
        error,
        onChange,
        ...rest
    } = props;
    return (
        <MuiAutocomplete
            {...props}
            sx={{
                ...rest.sx,
                ...(multiple &&
                    size === 'small' && {
                        '& .MuiChip-root': {
                            height: '100%'
                        }
                    }),
                '& .MuiAutocomplete-endAdornment': {
                    marginRight: 2
                },
                '& .MuiSvgIcon-root': {
                    '& > *': {
                        fill: 'none',
                        stroke: '#646E73'
                    }
                },
                '& .Mui-disabled': {
                    '& .MuiAutocomplete-endAdornment': {
                        display: 'none'
                    }
                }
            }}
            popupIcon={<SvgIcon component={ArrowDown} inheritViewBox />}
            getOptionDisabled={option => (isOptionDisabled ? getOptionDisabled(option) : false)}
            getOptionLabel={option =>
                typeof getOptionLabel === 'string' ? option[getOptionLabel] : getOptionLabel(option)
            }
            getOptionSelected={rest.getOptionSelected}
            fullWidth
            placeholder={rest.placeholder || label}
            renderOption={(props, option, state) =>
                isRenderOption ? renderOption(props, option, state) : <Box {...props}>{option[getOptionLabel]}</Box>
            }
            renderInput={params => <CustomInput placeholder={rest.placeholder} {...{label, size, error, params}} />}
        />
    );
};

export default Autocomplete;

Autocomplete.propTypes = {
    className: PropTypes.object,
    defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    disabled: PropTypes.bool,
    freeSolo: PropTypes.bool,
    getOptionDisabled: PropTypes.func,
    getOptionLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    getOptionSelected: PropTypes.func,
    isOptionDisabled: PropTypes.bool,
    isRenderOption: PropTypes.bool,
    label: PropTypes.string,
    limitTags: PropTypes.number,
    multiple: PropTypes.bool,
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    renderOption: PropTypes.any,
    rules: PropTypes.object,
    size: PropTypes.string,
    tagLink: PropTypes.string
};

Autocomplete.defaultProps = {
    disabled: false,
    freeSolo: false,
    getOptionLabel: 'value',
    limitTags: -1,
    multiple: false,
    rules: {},
    size: 'medium'
};
