const tabStyles = {
    MuiTabPanel: {
        styleOverrides: {
            root: {
                padding: 0
            }
        }
    },
    MuiTab: {
        styleOverrides: {
            root: {
                backgroundColor: '#fff',
                color: '#929698',
                borderTopLeftRadius: 12,
                borderTopRightRadius: 12,
                '&.Mui-selected': {
                    backgroundColor: '#D12E28',
                    color: '#FFF'
                },
                '&:not(:last-of-type)': {
                    marginRight: 8
                }
            },

            textColorSecondary: {
                color: '#BDBDBD',
                // fontWeight: '600',
                borderRadius: 0,
                borderBottom: ' 1px solid #BDBDBD',
                fontSize: '13px',
                lineHeight: '16px',
                '&.Mui-selected': {
                    background: 'inherit',
                    color: '#D12E28',
                    borderBottom: ' 1px solid #D12E28'
                },
                '&:not(:last-of-type)': {
                    marginRight: 0
                }
            }
        }
    }
};

export default tabStyles;
