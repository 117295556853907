import React, {createContext, useCallback, useState} from 'react';
import {Alert, Snackbar} from '@mui/material';

export const ToastContext = createContext({});

export const TOAST_TYPE = {
    SUCCESS: 'success',
    ERROR: 'error'
};

export const ToastProvider = ({children}) => {
    const [popUp, setPopUp] = useState(false);
    const [toast, setToast] = useState({});

    const addToast = useCallback(
        ({text, type}) => {
            setPopUp(true);
            if (!text) {
                switch (type) {
                    case TOAST_TYPE.SUCCESS: {
                        setToast({text: 'Успешно сохранено', type});
                        break;
                    }
                    case TOAST_TYPE.ERROR: {
                        setToast({text: 'Произошла ошибка. Данные не сохранены', type});
                        break;
                    }
                }
            } else {
                setToast({text, type});
            }
        },
        [setPopUp]
    );

    return (
        <ToastContext.Provider value={{addToast}}>
            {children}
            <Snackbar
                open={popUp}
                autoHideDuration={5000}
                onClose={() => setPopUp(false)}
                sx={{marginTop: 10, background: 'white', borderRadius: '10px'}}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                <Alert variant='outlined' severity={toast.type} onClose={() => setPopUp(false)} sx={{height: 'auto'}}>
                    {toast.text}
                </Alert>
            </Snackbar>
        </ToastContext.Provider>
    );
};
