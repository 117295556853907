import React, {createContext, useState} from 'react';
import PropTypes from 'prop-types';
import {useForm} from 'react-hook-form';
import {styled} from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import ContactForm from 'components/Header/components/ContactForm';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useMutation} from '@apollo/client';
import {withApollo} from 'config/apolloConfig';
import {GTABLE} from 'graphql/order/mutation/insertInfoIntoTable';
import Hint from 'src/views/order/view/components/Hint.js';
import {LoadingButton} from '@mui/lab';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Stack,
    Typography
} from '@mui/material';

const ModalDialog = styled(Dialog)(({theme}) => ({
    '& .MuiPaper-root': {
        borderRadius: '8px',
        width: 570,
        paddingBottom: '52px'
    },
    '& .MuiDialogContent-root': {
        overflowY: 'auto',
        padding: 0
    },
    '& .MuiDialogActions-root': {
        padding: 0,
        justifyContent: 'center'
    }
}));

const DialogHeader = props => {
    const {onClose, ...other} = props;

    return (
        <DialogTitle sx={{m: 0, p: 2, pb: 0, textAlign: 'end'}} {...other}>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        color: theme => theme.palette.grey[500]
                    }}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

DialogHeader.propTypes = {
    onClose: PropTypes.func.isRequired
};
export const ModalContext = createContext({});
export const ContactModal = ({children}) => {
    const [open, setOpen] = useState(false);
    const [hint, setHint] = useState(false);
    const handleClickModal = () => {
        reset({});
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        resetTable({});
        reset({});
    };
    const schema = yup.object().shape({
        name: yup.string().required('Обязательное поле'),
        number: yup
            .string()
            .required('Обязательное поле')
            .matches(/^[(][0-9]{3}[)][ ][0-9]{3}[-][0-9]{2}[-][0-9]{2}$/, 'Неверный формат номера'),
        comment: yup.string().required('Обязательное поле')
    });

    const form = useForm({
        resolver: yupResolver(schema)
    });
    const {reset, getValues, setValue, watch, setError, handleSubmit} = form;
    const [table, {data, loading, reset: resetTable}] = useMutation(GTABLE);
    const onSubmit = info => {
        table({
            variables: {
                input: {fullName: info?.name, phoneNumber: '8 ' + info?.number, comment: info?.comment}
            }
        })
            .then(res => {
                handleClose();
                setHint(true);
            })
            .catch(err => {
                alert('Что-то пошло не так.');
            });
    };
    return (
        <ModalContext.Provider value={{handleClickModal}}>
            {children}
            <ModalDialog
                component={'form'}
                onSubmit={handleSubmit(onSubmit)}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}>
                <DialogHeader id="customized-dialog-title" onClose={handleClose} />
                <Grid container sx={{mt: -1, textAlign: 'center'}}>
                    <Grid item xs={12}>
                        <Typography variant={'h4'} sx={{mb: 2}}>
                            Оставьте свои контакты
                        </Typography>
                        <Typography
                            variant={'body1'}
                            sx={{width: ['auto', 460], mx: [7.5, 'auto'], color: 'grey.description'}}>
                            В данный момент страница находится в работе. Оставьте нам свои контакты и укажите
                            интересующие вас вопросы. Мы обязательно свяжемся с вами в ближайшее время!
                        </Typography>
                    </Grid>
                </Grid>
                <DialogContent sx={{pt: 8.5, pb: 6, mx: 6}}>
                    <Box
                        sx={{
                            width: '100%',
                            typography: 'body1',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                        <ContactForm {...{form}} />
                    </Box>
                </DialogContent>
                <DialogActions sx={{mx: 6}}>
                    <LoadingButton
                        type={'submit'}
                        variant={'contained'}
                        color={'primary'}
                        loadingPosition={'start'}
                        loading={loading}
                        sx={{width: '100%', p: 1.25}}>
                        <Typography variant={'h4'} sx={{color: 'secondary.main', fontWeight: 400}}>
                            Отправить
                        </Typography>
                    </LoadingButton>
                </DialogActions>
            </ModalDialog>
            {hint && (
                // TODO: Цвет добавить в темы
                <Hint
                    text={'Ваша заявка успешно отправлена!'}
                    closeHint={() => setHint(false)}
                    color={'#82DB90'}></Hint>
            )}
        </ModalContext.Provider>
    );
};
export default withApollo({ssr: true})(ContactModal);
