import React from 'react';
import {TextField} from '@mui/material';

const CustomInput = ({label, size, error, params, placeholder, ...rest}) => {
    return (
        <TextField
            {...params}
            {...rest}
            label={label}
            error={!!error?.message}
            helperText={error?.message}
            size={size}
            variant={'standard'}
            InputLabelProps={{shrink: true}}
            placeholder={placeholder || label}
        />
    );
};

export default CustomInput;
