import MuseoSansCyrl500 from 'styles/font/MuseoSansCyrl-500.ttf';

const MuiCssBaseline = {
    styleOverrides: `
        @font-face { 
            font-family: 'MuseoSansCyrl';
            src: local('MuseoSansCyrl-500'),  url(${MuseoSansCyrl500});
            font-weight: normal;
            font-style: normal;
        },
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0; 
        }
        input[type=number] {
        -moz-appearance:textfield; /* Firefox */
        }`
};

export default MuiCssBaseline;
