import React from 'react';
import TextFieldControl from 'components/inputs/textField/TextFieldControl';
import DropdownControl from 'components/inputs/DropdownControl';
import {Grid, Typography} from '@mui/material';

const ContactForm = props => {
    const {form, ...other} = props;
    const {control} = form;
    return (
        <Grid container item xs={12} alignItems={'center'} spacing={3} sx={{mt: 2, mb: 7}}>
            <Grid item xs={12} sx={{flexBasis: '100%'}}>
                <Typography variant={'body2'} sx={{color: '#242E45', pb: 0.5}}>
                    ФИО
                </Typography>
                <TextFieldControl
                    autoFocus={'true'}
                    control={control}
                    name={'name'}
                    placeholder={'Введите полное имя'}
                />
            </Grid>
            <Grid container item xs={12} alignItems={'end'} spacing={3} sx={{flexWrap: 'nowrap'}}>
                <Grid item>
                    <Typography variant={'body2'} sx={{color: '#242E45', pb: 0.5}}>
                        Телефон
                    </Typography>
                    <DropdownControl
                        disabled
                        defaultValue={'+7'}
                        items={[{id: '+7'}]}
                        control={control}
                        name={'phoneCode'}
                        placeholder={'+7'}
                    />
                </Grid>
                <Grid item sx={{flexGrow: 1}}>
                    <TextFieldControl
                        mask={'(000) 000-00-00'}
                        type="number"
                        control={control}
                        name={'number'}
                        placeholder={'(999) 888-77-66'}
                    />
                </Grid>
            </Grid>

            <Grid item md={12} sx={{flexBasis: '100%'}}>
                <Typography variant={'body2'} sx={{color: '#242E45', pb: 0.5}}>
                    Комментарий
                </Typography>
                <TextFieldControl control={control} name={'comment'} placeholder={''} multiline={true} rows={2} />
            </Grid>
        </Grid>
    );
};

export default ContactForm;
