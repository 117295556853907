import PropTypes from 'prop-types';
import React from 'react';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterMoment from '@mui/lab/AdapterMoment';
import {Controller} from 'react-hook-form';
import DateTimePicker from '@mui/lab/DateTimePicker';
import 'moment/locale/ru';
import {TextField} from 'components/inputs';
import moment from 'moment';
import {styled} from '@mui/material/styles';
import Calendar from '/public/icons/calendar.svg';

const TimePickerControl = ({control, name, label, ...rest}) => {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Controller
                defaultValue={rest.defaultValue}
                name={name}
                control={control}
                render={({field: {ref, ...props}, fieldState: {error}}) => {
                    return (
                        <DateTimePicker
                            {...props}
                            {...rest}
                            components={{
                                OpenPickerIcon: Calendar
                            }}
                            renderInput={params => {
                                return (
                                    <TextField
                                        {...params}
                                        helperText={error?.message}
                                        InputLabelProps={{shrink: true}}
                                        value={moment(props.value).format('DD.MM.YYYY HH:mm')}
                                        // type={'text'}
                                        // mask={'00.00.0000 00:00'}
                                        error={error?.message}
                                        variant={'standard'}
                                        size={rest.size}
                                    />
                                );
                            }}
                        />
                    );
                }}
            />
        </LocalizationProvider>
    );
};
export default TimePickerControl;

TimePickerControl.propTypes = {
    control: PropTypes.object.isRequired,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    size: PropTypes.string
};

TimePickerControl.defaultProps = {
    size: 'medium'
};
