import React from 'react';
import {Checkbox, FormControl, FormControlLabel, FormHelperText} from '@mui/material';
import {Controller} from 'react-hook-form';

const CheckboxControl = ({control, name, label}) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({field, fieldState: {error}}) => (
                <FormControl fullWidth>
                    <FormControlLabel
                        control={<Checkbox onChange={e => field.onChange(e.target.checked)} checked={field.value} />}
                        label={label}
                    />
                    {!!error?.message && (
                        <FormHelperText sx={{position: 'absolute', top: '100%'}}>{error.message}</FormHelperText>
                    )}
                </FormControl>
            )}
        />
    );
};
export default CheckboxControl;
