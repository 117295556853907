import React from 'react';
import PropTypes from 'prop-types';
import {Controller} from 'react-hook-form';
import {FormControlLabel, Radio, RadioGroup} from '@mui/material';

const RadioGroupControl = ({control, name, radioButtonConfig, defaultValue, onChange, disabled}) => {
    return (
        <Controller
            defaultValue={defaultValue}
            render={({field}) => (
                <RadioGroup {...field} row>
                    {radioButtonConfig.map((button, key) => {
                        return (
                            <FormControlLabel
                                sx={{mr: {xs: 3, md: 4}}}
                                disabled={disabled}
                                key={`radio-button-${name}-${key}`}
                                value={button.value}
                                control={<Radio />}
                                label={button.label}
                            />
                        );
                    })}
                </RadioGroup>
            )}
            name={name}
            control={control}
        />
    );
};

export default RadioGroupControl;

RadioGroupControl.propTypes = {
    control: PropTypes.object.isRequired,
    defaultValue: PropTypes.string,
    name: PropTypes.string.isRequired,
    radioButtonConfig: PropTypes.array.isRequired
};
